@import "variables";

.baseLayout {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.content{
	height: 100%;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-evenly;
	width: 100%;
	display: flex;
	transition: all 1s;
}

.header {
	background-color: $--darkblue; 
	color: black;
	text-align: center; 
	font-size: 6vh;
	font-weight: bold;
	width: 100%;
	height: 7vh;
	z-index: 100;
}


.footer {
	width: 100%;
	background-color: $--darkblue; 
	color: $--brightyellow;
	text-align: center;
	z-index: 100;
	bottom: 0;
}

.clickable {
	cursor: pointer;
}

.mainContent {
	background: $--brightyellow;
	overflow: auto;
}

.logoContainer{
	background: url(../ressources/images/KK2.gif);
	background-size: cover;
	width: 70px;
	height: 100%;
	margin-right: 10px;
}

.kkContainer{
	cursor: pointer;
	width: fit-content;
	margin: 10px 5px;
	color: $--brightyellow;
	display: flex;
	flex-direction: row;
	align-items: center;
    justify-content: center;
}





