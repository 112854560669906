@import "variables";

.linkContainer {
  float: left;
  width: 33.333%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  background-color: black;
  transition: all 0.5s ease 0s;
}

.linkContainer:last-child {
  width: 33.334%;
}

.linkContainer:hover .textbox{
  opacity: 1;
}

.imageContainer{
  height: 100%;
  min-height: fit-content;
  width: 100%;
  box-sizing: border-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 2em;
}

.leftContainer{
  background-image: url(../ressources/images/K1024_zeichnung.JPG);
}

.middleContainer{
  background-image: url(../ressources/images/K1024_fabrik1.JPG);
}

.rightContainer{
  background-image: url(../ressources/images/K1024_network.JPG);
}

.textbox {
  color: $--brightyellow; 
  background-color: $--darkblue;
  font-weight: bold;
  font-size: 2.3em;
  opacity: 0.75;
  z-index: 1000;
  display: inline-block;
  text-align: center;
  padding: 0.5em 1em;
  transition: all 0.5s ease 0s;
}

.textbox:hover {
  cursor: pointer;
}

.leftTextbox {
  left: 8.33%;
}

.middleTextbox {
  left: 43.333%;
}

.rightTextbox {
  right: 8.33%
}

.wide {
   width: 90% !important;
}

.small {
  width: 5% !important;
}

.subBoxContainer {
  height: inherit;
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
}

.subContainerElement {
  height: fit-content;
  padding: 1em;
  background-color: #2C3D42;
  opacity: 1;
  color: #F5ECE4;
  margin: 1em;
  text-align: left;

}

.withIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subContainerElement h2 {
  text-align: center;
  margin-block-start: 0;
}

.column2 {
  height: inherit;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.forMap {
  height: 75%;
  min-width: 40%;
  flex-grow: 4;
}

.content-appear {
  opacity: 0.01;
}

.content-appear.content-appear-active {
  opacity: 1;
  transition: opacity 0.2s ease 0.05s;
}

.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 0px;
  top: 0px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
