$--black: #000;
$--white: #fff;
$--darkblue: #2C3D42;
$--lightblue: #79E0F7;
$--greyblue: #546F75;
$--brightyellow: #F5ECE4;

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.fullWidth {
    width: 100%;
}

.column {
    flex-direction: column;
    display: flex;
}

.row {
    flex-direction: row;
    display: flex;
}

.shadowForBox {
    box-shadow: 2px 2px 5px black;
}

.clickable {
    cursor: pointer;
}

.scrolling {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.scrolling::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.bordered {
    text-align: center;
    font-size: 2vh;
    margin: 0.5em 1em 0.5em 1em;
    padding: 0.5em;
    width: 12em;
    background: #F5ECE4;
    color: #2C3D42;
    font-weight: bold;
}

.test2 {
    width: 30em;
}

.heading {
    font-size: 6vh;
    font-weight: bold;
}

.siteHeading {
    font-size: 5vh;
    font-weight: bold;
}

.boxHeading {
    font-size: 3vh;
    font-weight: bold;
}

.text {
    font-size: 2.5vh;
}

.smalltext {
    font-size: 2vh;
}
